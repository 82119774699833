import React from 'react'
import Layout from '../../components/layout'
import ContentHero from '../../components/molecules/ContentHero'
import acfLayout from '../../lib/acfLayout'
import styles from './dynamic-layout-with-content-heading.module.scss'

export default ({ pageContext, fromREST }) =>
  <Layout>
    <ContentHero title={pageContext.title} content={pageContext.content} />
    <div className={styles.dynamicLayoutWithContentHeading}>
      { acfLayout({ layout: pageContext.acf.modules_page, fromREST }) }
    </div>
  </Layout>
